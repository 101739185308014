import React from "react";
import { Avatar as AntdAvatar } from "antd";
import styled from "styled-components";

const StyledAntdAvatar = styled(AntdAvatar)`
  background-color: #ccc;
`;
const StyledB = styled.b<{ fontSize?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "inherit")};
`;

interface Props {
  firstName: NullableAndOptional<string>;
  fontSize?: string;
  lastName: NullableAndOptional<string>;
  size?: number; // default: 23
}

export default function Avatar({ firstName, fontSize, lastName, size }: Props) {
  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : "";
  return (
    <StyledAntdAvatar size={size || 23}>
      <StyledB fontSize={fontSize}>{initials}</StyledB>
    </StyledAntdAvatar>
  );
}
